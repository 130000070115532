export const environment = {
  production: false,
  panel: 'kesar_',
  // domain: "kanhaexch.com",
  // baseUrl: "https://api.kanhaexch.com",
  domain: "mango777.club",
  baseUrl: "https://api.mango777.club",
  trafficTag: '',
  pollers: {
      oddsData: 600,
      listOddsData: 10000,
      scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  isMobile: true,
  isBetOpenEnv: false,
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  desktopStyles: [],
  mobileStyles: [],
  host: {
      domain: '',
      name: '',
      dName: '',
      ext: '',
      theme: '',
      email: '',
      demoLogin: false,
      tawkScriptSrc: '',
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      iframeDomainUrl: '',
      signUpActive: false,
      whatsapp: '',
      facebook: '',
      youtube: '',
      telegram: '',
      instagram: '',
      contactNo: '',
      pusher: {
          key: '',
          cluster: ''
      },
      themePath: [''],
      googleAnalytics: {
          active: false,
          googleAnalyticsTrackingId: '',
          googleSiteVerification: ''
      },
      siteTitle: '',
      meta_dec: '',
      meta_keyword: '',
  },
  hosts: [
      {
          domain: 'localhost',
          name: 'localhost',
          dName: 'LocalHost',
          ext: '.com',
          theme: 'blue-body',
          email: 'contactmybetbhai@gmail.com',
          demoLogin: true,
          tawkScriptSrc: '',
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          iframeDomainUrl: '',
          signUpActive: false,
          whatsapp: '',
          facebook: '',
          youtube: '',
          telegram: '',
          instagram: '',
          contactNo: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          googleAnalytics: {
              active: true,
              googleAnalyticsTrackingId: 'G-LXQS8N0KWR',
              googleSiteVerification: 'qSYtDPfb91Dad8EWPNgdJ3wshM2B6dVx-wG0ycn8Vrw'
          },
          siteTitle: '',
          meta_dec: '',
          meta_keyword: '',
      },
      {
          domain: 'kesar365.com',
          name: 'kesar365',
          dName: 'kesar365',
          ext: '.com',
          theme: 'blue-body',
          email: 'infokesar4158@gmail.com',
          demoLogin: true,
          tawkScriptSrc: 'https://embed.tawk.to/64cbc283cc26a871b02d1099/1h6tuvkou',
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          iframeDomainUrl: 'https://panel.freeplay24.com/',
          signUpActive: true,
          whatsapp: 'https://linktr.ee/kesar365',
          facebook: 'https://www.facebook.com/officialkesar365',
          youtube: 'https://www.youtube.com?@official_kesar365',
          telegram: 'https://t.me/+j6JjB1Xg9680NzFl',
          instagram: 'www.instagram.com/officialkesar365',
          contactNo: '916262568685',
          pusher: {
              key: 'fdf9a08dc1f2bfa9cffa',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          googleAnalytics: {
              active: true,
              googleAnalyticsTrackingId: 'G-LXQS8N0KWR',
              googleSiteVerification: 'qSYtDPfb91Dad8EWPNgdJ3wshM2B6dVx-wG0ycn8Vrw'
          },
          siteTitle: 'Kesar365 | Most Trusted Online Cricket ID Provider in India',
          meta_dec: 'India’s fastest growing exchange platforms for Multiple sports like Cricket, Tennis and Football! Get your own online Cricket ID with us!',
          meta_keyword: 'online cricket id, online betting id, best betting site in India, cricket betting id provider, trusted online betting id, online casino id',
      },
      {
          domain: 'ambaniexch.com',
          name: 'ambaniexch',
          dName: 'ambaniexch',
          ext: '.com',
          theme: 'blue-body',
          email: 'infoambaniexch@gmail.com',
          demoLogin: true,
          tawkScriptSrc: '',
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          iframeDomainUrl: 'https://panel.freeplay24.com/',
          signUpActive: true,
          whatsapp: 'https://wa.link/ambaniexch',
          facebook: 'https://www.facebook.com/people/Ambani/61563597401367/',
          youtube: 'https://www.youtube.com/channel/UCOfpvowr0IslruN2NXuwUjw',
          telegram: 'https://t.me/ambaniexch',
          instagram: 'https://www.instagram.com/ambaniexch/',
          contactNo: '',
          pusher: {
              key: 'fdf9a08dc1f2bfa9cffa',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-ambaniexch.css'],
          googleAnalytics: {
              active: false,
              googleAnalyticsTrackingId: '',
              googleSiteVerification: ''
          },
          siteTitle: '',
          meta_dec: '',
          meta_keyword: '',
      },
      {
          domain: 'betmaxpk.com',
          name: 'betmaxpk',
          dName: 'betmaxpk',
          ext: '.com',
          theme: 'blue-body',
          email: 'infobetmaxpk@gmail.com',
          demoLogin: true,
          tawkScriptSrc: '',
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          iframeDomainUrl: 'https://panel.freeplay24.com/',
          signUpActive: true,
          whatsapp: '#',
          facebook: '#',
          youtube: '#',
          telegram: '#',
          instagram: '#',
          contactNo: '#',
          pusher: {
              key: 'fdf9a08dc1f2bfa9cffa',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-reddybook.css'],
          googleAnalytics: {
              active: false,
              googleAnalyticsTrackingId: '',
              googleSiteVerification: ''
          },
          siteTitle: '',
          meta_dec: '',
          meta_keyword: '',
      },
      {
          domain: 'betworld.club',
          name: 'betworld',
          dName: 'betworld',
          ext: '.club',
          theme: 'blue-body',
          email: '',
          demoLogin: true,
          tawkScriptSrc: '',
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          iframeDomainUrl: 'https://panel.freeplay24.com/',
          signUpActive: true,
          whatsapp: '',
          facebook: '',
          youtube: '',
          telegram: '',
          instagram: '',
          contactNo: '',
          pusher: {
              key: 'fdf9a08dc1f2bfa9cffa',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-ambaniexch.css'],
          googleAnalytics: {
              active: false,
              googleAnalyticsTrackingId: '',
              googleSiteVerification: ''
          },
          siteTitle: '',
          meta_dec: '',
          meta_keyword: '',
      },
      {
          domain: 'khandada247.com',
          name: 'khandada247',
          dName: 'khandada247',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          demoLogin: true,
          tawkScriptSrc: '',
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          iframeDomainUrl: 'https://panel.freeplay24.com/',
          signUpActive: true,
          whatsapp: '',
          facebook: '',
          youtube: '',
          telegram: '',
          instagram: '',
          contactNo: '',
          pusher: {
              key: 'fdf9a08dc1f2bfa9cffa',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-ambaniexch.css'],
          googleAnalytics: {
              active: false,
              googleAnalyticsTrackingId: '',
              googleSiteVerification: ''
          },
          siteTitle: '',
          meta_dec: '',
          meta_keyword: '',
      },
      {
          domain: 'diamond365.win',
          name: 'diamond365',
          dName: 'diamond365',
          ext: '.win',
          theme: 'blue-body',
          email: '',
          demoLogin: true,
          tawkScriptSrc: '',
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          iframeDomainUrl: 'https://panel.freeplay24.com/',
          signUpActive: true,
          whatsapp: 'https://wa.link/diamond365win',
          facebook: '',
          youtube: '',
          telegram: '',
          instagram: '',
          contactNo: '',
          pusher: {
              key: 'fdf9a08dc1f2bfa9cffa',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-ambaniexch.css'],
          googleAnalytics: {
              active: false,
              googleAnalyticsTrackingId: '',
              googleSiteVerification: ''
          },
          siteTitle: '',
          meta_dec: '',
          meta_keyword: '',
      },
  ],
};
